/*
 * @Author: zhangyumei
 * @Date: 2024-09-12 18:46:59
 * @LastEditors: zhangyumei
 * @LastEditTime: 2024-09-13 11:35:02
 * @Description: TDK设置
 */
const tdkInfo = {
  Home: {
    title:
      "RFID固定资产管理系统解决方案-RFID资产管理-RFID资产管理系统-RFID资产管理软件-常达智能",
    keywords:
      "RFID资产管理, 固定资产管理系统, 资产管理解决方案, 数字化资产管理, RFID标签, 资产信息数字化, 工业资产管理, RFID固定资产盘点, 资产管理软件, 智能资产管理",
    description:
      "常达智能物联专注于RFID资产管理系统的研发与生产，为企业提供先进的固定资产管理解决方案。我们的RFID技术能够精准追踪和管理企业资产，帮助客户实现资产信息数字化和智能化管理，提升企业运营效率。",
  },
  FixedAssets: {
    title: "RFID固定资产管理解决方案-高效资产跟踪与监控",
    keywords:
      "RFID固定资产管理, 资产跟踪, 资产监控, 智能资产管理, 资产数字化, RFID技术解决方案",
    description:
      "利用先进的RFID技术提升企业的资产管理效率。我们的解决方案提供实时资产跟踪、精准数据管理，帮助企业实现资产的智能化和数字化管理，降低运营成本。",
  },
  NFC标签: {
    title: "RFID标签-NFC标签定制-标签定制",
    keywords:
      "NFC标签, 近场通讯, RFID标签, 非接触式数据传输, 高频芯片, 标签定制, 智能管理",
    description:
      "常达智能提供多种NFC标签，适用于零售、交通、博物馆等领域。我们的NFC标签支持高频非接触式数据传输，具有高安全性和便捷性，满足个性化编码和打印需求，助力企业实现智能化管理。",
  },
};
const setTDK = (routeName) => {
  console.log("setTDK", routeName);
  if (!routeName) return "";
  let obj = tdkInfo[routeName];
  if (obj) {
    removeMeta();
    document.title = obj.title;
    let titleEl = document.querySelector("title");
    let desEl = document.querySelector("meta[name='description']");
    if (desEl) {
      desEl.setAttribute("content", obj.description);
    } else {
      const metaTag = document.createElement("meta");
      metaTag.name = "description";
      metaTag.content = obj.description;
      titleEl.parentNode.insertBefore(metaTag, titleEl.nextSibling);
    }
    let kwEl = document.querySelector("meta[name='keywords']");
    if (kwEl) {
      kwEl.setAttribute("content", obj.keywords);
    } else {
      const metaTag = document.createElement("meta");
      metaTag.name = "keywords";
      metaTag.content = obj.keywords;
      titleEl.parentNode.insertBefore(metaTag, titleEl.nextSibling);
    }
  } else {
    let desEl = document.querySelector(
      "meta[name='description'][data-vue-meta-info='true']"
    );
    let desEl2 = document.querySelector(
      "meta[name='description']:not([data-vue-meta-info]"
    );
    if (desEl && desEl2) {
      desEl2.remove();
    }

    let kwEl = document.querySelector(
      "meta[name='keyWords'][data-vue-meta-info='true']"
    );
    let kwEl2 = document.querySelector(
      "meta[name='keywords']:not([data-vue-meta-info]"
    );
    if (kwEl && kwEl2) {
      kwEl2.remove();
    }
  }
};

const removeMeta = () => {
  let desEl = document.querySelector(
    "meta[name='description'][data-vue-meta-info='true']"
  );
  if (desEl) {
    desEl.remove();
  }
  let kwEl = document.querySelector(
    "meta[name='keyWords'][data-vue-meta-info='true']"
  );
  if (kwEl) {
    kwEl.remove();
  }
};
export default {
  setTDK: setTDK,
  TDK: tdkInfo,
};
