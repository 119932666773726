import "core-js/modules/es.function.name.js";
import TDK from "/public/data/TDK.js";
import router from "@/router";
export default {
  name: "Container",
  mounted: function mounted() {// const routeName = router.currentRoute.value.name;
    // console.log("route.name", routeName);
    // TDK.setTDK(routeName);
  },
  watch: {
    $route: function $route(to) {
      this.initTdk(to);
    }
  },
  created: function created() {
    this.initTdk(router.currentRoute.value);
  },
  methods: {
    initTdk: function initTdk(to) {
      var routeName = to.name;

      if (routeName === "Product") {
        var queryName = to.query.name;

        if (queryName) {
          TDK.setTDK(queryName);
        }
      } else {
        TDK.setTDK(routeName);
      }
    }
  }
};